// src\index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// Import Bootstrap JS (requires jQuery)
import 'bootstrap/dist/js/bootstrap.bundle.min';
// Import jQuery
import $ from 'jquery';

// Make jQuery available globally (optional, if needed by your scripts)
window.$ = $;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);