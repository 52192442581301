import React from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink for navigation

export function Navbar() {
  return (
    <nav className="main-header navbar navbar-expand-md navbar-dark">
      <div className="container">
        <a href="https://www.kingrango.com" className="navbar-brand">
          <img src="https://www.kingrango.com/images/kingrango.png" alt="King Rango Logo" className="brand-image elevation-3" />
          <span className="brand-text font-weight-light">King Rango Tool</span>
        </a>
        <button
          className="navbar-toggler order-1"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse order-3" id="navbarCollapse">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink to="/" className="nav-link" activeClassName="active">Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/resellers" className="nav-link" activeClassName="active">Resellers</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/supported-models" className="nav-link" activeClassName="active">Supported Models</NavLink>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Login
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="https://user.kingrango.com/login" target="_blank" rel="noopener noreferrer">Login</a>
                <a className="dropdown-item" href="https://user.kingrango.com/register" target="_blank" rel="noopener noreferrer">Register</a>
                <div className="dropdown-divider"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
