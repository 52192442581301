import React, { useEffect, useState } from 'react';
import { Table, Spinner, Alert, Card } from 'react-bootstrap';

export function ResellersPage() {
  const [resellers, setResellers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch resellers data from the API
    fetch('https://proxytool.kingrango.com/api/resellers')
      .then(response => {
        if (!response.ok) {
          return response.text().then(text => {  // Read response as text to log
            throw new Error(`HTTP error ${response.status}: ${text}`);
          });
        }
        return response.json();
      })
      .then(data => {
        setResellers(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching resellers:', error);
        setError(`Failed to fetch data: ${error.message}`);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" />
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center mt-5">
        <Alert variant="danger">
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <Card>
        <Card.Header className="text-muted border-bottom-0">
          Resellers
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Country</th>
                <th>WhatsApp/Call.</th>
              </tr>
            </thead>
            <tbody>
              {resellers.map((reseller, index) => (
                <tr key={reseller.id}>
                  <td>{index + 1}</td>
                  <td>{reseller.name}</td>
                  <td>{reseller.country}</td>
                  <td>{reseller.contact}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
}
