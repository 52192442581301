import React, { useState } from 'react';
import './styles/styles.css';
import Calculator from './components/Calculator';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { TelegramIcon } from './components/TelegramIcon';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Modal, Button } from 'react-bootstrap'; // Import Bootstrap components
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import Router components
import { ResellersPage } from './components/ResellersPage'; // Import ResellersPage
import { SupportedModels } from './components/SupportedModels';

function App() {
  const [showModal, setShowModal] = useState(false);
  const [showWhatsAppModal, setShowWhatsAppModal] = useState(false);

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleWhatsAppButtonClick = () => {
    setShowWhatsAppModal(true);
  };

  const handleCloseWhatsAppModal = () => {
    setShowWhatsAppModal(false);
  };

  const handleLoginRedirect = () => {
    window.open('https://user.kingrango.com/login', '_blank');
  };

  const handleRegisterRedirect = () => {
    window.open('https://user.kingrango.com/register', '_blank');
  };

  return (
    <Router>
      <div className="wrapper">
        <Navbar />
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid"></div>
          </div>
          <section className="content">
            <div className="container">
              <Routes>
                {/* Route for ResellersPage */}
                <Route path="/resellers" element={<ResellersPage />} />
                
                {/* Route for Supported Models */}
                <Route path="/supported-models" element={<SupportedModels />} />

                {/* Default route */}
                <Route path="/" element={
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <h3 className="d-inline-block d-sm-none">King Rango</h3>
                      <div className="col-12">
                        <img src="https://www.kingrango.com/images/KING RANGO.jpeg" className="product-image" alt="King Rango Tool Image" />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <h3 className="my-3" align="center">King Rango Tool</h3>
                      <p align="center">Network Unlock by IMEI | Software Fixing Tool | Black Screen Fix Tool</p>
                      
                      <div className="form-group">
                        <div className="col-12">
                          <br />
                          <div className="col-12">
                            <button 
                              className="btn btn-success btn-block"
                              onClick={handleButtonClick}
                            >
                              <i className="fa fa-download"></i> Download King Rango Tool v1.0.0
                            </button>
                          </div>
                          <br />
                          <div className="col-12">
                            <button 
                              className="btn btn-success btn-block"
                              onClick={handleWhatsAppButtonClick}
                            >
                              <i className="fa fa-whatsapp"></i> Join WhatsApp Channel
                            </button>
                          </div>
                        </div>
                        <Calculator />
                      </div>
                    </div>
                  </div>
                } />
              </Routes>
            </div>
          </section>
        </div>
        <TelegramIcon />
        <Footer />

        {/* Download Modal */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Download Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>King Rango Tool v1.0.0</h4>
            <ul>
              <li>Network Unlock</li>
              <li>Software Fixing Tool</li>
              <li>Black Screen Fix Tool</li>
              <li>Window Tool all x64/x86 Bits</li>
              <li>Version: 1.0.0</li>
              <li>Release Date: </li>
              <li>Minimum of 10 Credits Needed to Activate your Account</li>
              <li>Login or Register Account to have Access to the Tool</li>
            </ul>
            <div className="mt-3">
              <Button 
                variant="success"
                onClick={handleLoginRedirect}
                className="me-2"
              >
                Go to Login Page
              </Button>
              <Button 
                variant="success"
                onClick={handleRegisterRedirect}
                className="me-2"
              >
                Go to Register Page
              </Button>
              {/* New Download Button */}
              <Button 
                variant="success" 
                onClick={() => window.open('https://www.mediafire.com/file/imo9528kyab6ucl/KING_RANGO_TOOL.zip/file', '_blank')}
                className="mt-3"
              >
                <i className="fa fa-download"></i> Download King Rango Tool
              </Button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* WhatsApp Modal */}
        <Modal show={showWhatsAppModal} onHide={handleCloseWhatsAppModal}>
          <Modal.Header closeButton>
            <Modal.Title>Join Our WhatsApp Channel</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Stay updated with our latest tools and features</h5>
            <p>Click the button below to join our WhatsApp channel:</p>
            <div className="mt-3">
              <Button 
                variant="success"
                onClick={() => window.open('https://whatsapp.com/channel/0029Vak8LnW84OmAoza2H60O', '_blank')}
              >
                Join via WhatsApp
              </Button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseWhatsAppModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Router>
  );
}

export default App;
