// src\components\TelegramIcon.js
import React from 'react';

export function TelegramIcon() {
  return (
    <div className="telegram-icon">
      <a href="https://t.me/kingrango_dev" className="telegram-link" target="_blank" rel="noopener noreferrer">
        <i className="fa fa-telegram"></i>
      </a>
    </div>
  );
}
