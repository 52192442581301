// src\components\Footer.js
import React from 'react';

export function Footer() {
  return (
    <footer className="main-footer footer-expand bg-dark text-light py-3">
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-center text-md-start">
            <strong>King Rango Tool ( Version 1.0.3 )</strong>
          </div>
          <div className="col-md-6 text-center text-md-end">
            Developed by <a href="https://www.kingrango.com" className="text-light" rel="noopener noreferrer" target="_blank">King Rango Team</a>
          </div>
        </div>
      </div>
    </footer>
  );
}
