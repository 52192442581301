// src\components\Calculator.js
import React, { useState } from 'react';
import '../styles/Calculator.css';

export default function Calculator() {
  const [credits, setCredits] = useState('');
  const [currency, setCurrency] = useState('usd');
  const [result, setResult] = useState('');

  // Define cost per credit and exchange rates
  const costPerCreditUSD = 1.00;
  const exchangeRateTZS = 2800;
  const exchangeRateKSH = 137;
  const exchangeRateUGX = 3890;
  const exchangeRateZAR = 19; 
  const exchangeRateNGN = 1620;

  const handleSubmit = (event) => {
    event.preventDefault();
    let totalCost;

    switch (currency) {
      case 'usd':
        totalCost = credits * costPerCreditUSD;
        setResult(`The total cost for ${credits} credits is $${totalCost.toFixed(2)} USD.`);
        break;
      case 'tzs':
        totalCost = credits * costPerCreditUSD * exchangeRateTZS;
        setResult(`The total cost for ${credits} credits is ${totalCost.toFixed(2)} TZS.`);
        break;
      case 'ksh':
        totalCost = credits * costPerCreditUSD * exchangeRateKSH;
        setResult(`The total cost for ${credits} credits is ${totalCost.toFixed(2)} KSH.`);
        break;
      case 'ugx':
        totalCost = credits * costPerCreditUSD * exchangeRateUGX;
        setResult(`The total cost for ${credits} credits is ${totalCost.toFixed(2)} UGX.`);
        break;
      case 'zar':
        totalCost = credits * costPerCreditUSD * exchangeRateZAR;
        setResult(`The total cost for ${credits} credits is ${totalCost.toFixed(2)} ZAR.`);
        break;
      case 'ngn':
        totalCost = credits * costPerCreditUSD * exchangeRateNGN;
        setResult(`The total cost for ${credits} credits is ${totalCost.toFixed(2)} NGN.`);
        break;
      default:
        setResult('Please select a valid currency.');
        break;
    }
  };

  return (
    <div className="calculator">
      <h1>Credit Cost Calculator</h1>
      <form id="calculatorForm" onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="credits">Enter the number of credits:</label>
          <input
            type="number"
            id="credits"
            name="credits"
            value={credits}
            onChange={(e) => setCredits(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="currency">Choose your currency:</label>
          <select
            id="currency"
            name="currency"
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            required
          >
            <option value="usd">USD</option>
            <option value="tzs">TZS</option>
            <option value="ksh">KSH</option>
            <option value="ugx">UGX</option>
            <option value="zar">ZAR</option>
            <option value="ngn">NGN</option>
          </select>
        </div>
        <button type="submit">Calculate</button>
      </form>
      <div id="result">{result}</div>
    </div>
  );
}
