import React, { useEffect, useState } from 'react';
import { Table, Spinner, Alert, Card, Form } from 'react-bootstrap';

export function SupportedModels() {
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [filteredModels, setFilteredModels] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch brands data
    const fetchBrands = async () => {
      try {
        const response = await fetch('https://proxytool.kingrango.com/api/brands');
        if (!response.ok) {
          throw new Error(`Failed to fetch brands: ${response.statusText}`);
        }
        const data = await response.json();
        setBrands(data);
      } catch (error) {
        console.error('Error fetching brands:', error);
        setError(`Failed to fetch brands: ${error.message}`);
      }
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    // Fetch models data for the selected brand
    const fetchModels = async () => {
      if (selectedBrand) {
        try {
          const response = await fetch(`https://proxytool.kingrango.com/api/models/${selectedBrand}`);
          if (!response.ok) {
            const text = await response.text(); // Read response as text for logging
            throw new Error(`Failed to fetch models: ${response.status} ${response.statusText}. Response: ${text}`);
          }
          const data = await response.json();
          setModels(data);
          setFilteredModels(data);
        } catch (error) {
          console.error('Error fetching models:', error);
          setError(`Failed to fetch models: ${error.message}`);
        } finally {
          setLoading(false);
        }
      } else {
        setModels([]);
        setFilteredModels([]);
      }
    };

    fetchModels();
  }, [selectedBrand]);

  useEffect(() => {
    // Filter models based on selected criteria
    const filtered = models.filter(model =>
      (selectedModel ? model.model_name.toLowerCase().includes(selectedModel.toLowerCase()) : true)
    );
    setFilteredModels(filtered);
  }, [selectedModel, models]);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" />
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center mt-5">
        <Alert variant="danger">
          <Alert.Heading>Error!</Alert.Heading>
          <p>{error}</p>
        </Alert>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <Card>
        <Card.Header className="text-muted border-bottom-0">
          Supported Models
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group controlId="brandSelect">
              <Form.Label>Select Brand</Form.Label>
              <Form.Control 
                as="select" 
                value={selectedBrand} 
                onChange={(e) => setSelectedBrand(e.target.value)}
              >
                <option value="">All Brands</option>
                {brands.map(brand => (
                  <option key={brand.brand_id} value={brand.brand_id}>
                    {brand.brand_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="modelSearch">
              <Form.Label>Search Model</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Search by model name" 
                value={selectedModel}
                onChange={(e) => setSelectedModel(e.target.value)}
              />
            </Form.Group>
          </Form>
          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>#</th>
                <th>Model Name</th>
                <th>Brand Name</th>
                <th>Credits Usage</th>
              </tr>
            </thead>
            <tbody>
              {filteredModels.length > 0 ? (
                filteredModels.map((model, index) => (
                  <tr key={model.model_id}>
                    <td>{index + 1}</td>
                    <td>{model.model_name}</td>
                    <td>{brands.find(brand => brand.brand_id === model.brand_id)?.brand_name || 'Unknown'}</td>
                    <td>{model.credit_usage}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">No models found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
}
